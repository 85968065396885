import axios from 'axios'
import saveAs from 'file-saver'
import canvg from 'canvg'

export function capture(type, presenter, action) {
	/////////////////////////// CONFIG //////////////////////////

	var message = 'Připravujeme pro Vás'
	var exportForm = $('#frm-exportForm')
	var exportTypeInput = $('#exportType')
	var downloadMessage = $('#downloadMessage')
	var divlist = $('.export')
	var divcount = divlist.length

	////////////////////////////////////////////////////////////

	var fileType = 'pptx'

	exportTypeInput.val(fileType)
	downloadMessage.text(message + ' ' + fileType.toUpperCase())
	exportForm.attr('action', '/' + presenter + '/' + action)

	resizeScreen(type)
	setTimeout(function() {
		nextStep(0, divcount, divlist, exportForm)
	}, 1000)
}

export function resizeScreen(type) {
	$('#loader').addClass('progress')

	$('#settings, #menu').remove() // odstraní zbytečné divy

	var fixedWidth = 900 // nastaví fixní rozměr pro export

	$('aside').hide()
	$('.navbar').hide()
	$('main')
		.css('padding', '0')
		.width(fixedWidth)

	$('body p').css('font-feature-settings', '"liga" 0') // fix pro spatne vykreslovani ligatur u html2canvas v chrome
	$('.container').css({
		width: fixedWidth + 'px',
		'max-width': fixedWidth + 'px',
		margin: 0,
		padding: 0,
		left: 0,
	})

	show.charts() // Highcharts reflow
}

export function nextStep(i, divcount, divlist, exportForm) {
	if (i == divcount) {
		exportForm.submit()
		return
	}

	var currentDiv = divlist[i]

	if ($(currentDiv).find('div[data-highcharts-chart]').length > 0) {
		var graphDiv = $(currentDiv).find('div[data-highcharts-chart]')
		var borderWidth = 8
		var newWidth = $(currentDiv).width() + borderWidth
		var newHeight = $(currentDiv).height() + borderWidth
		var h2size = $(currentDiv)
			.find('.head_data_component ')
			.css('font-size')
		var newHeaderText = $(currentDiv)
			.find('.head_data_component ')
			.text()
		var newHeaderTextColor = $(currentDiv)
			.find('.head_data_component ')
			.css('color')
		if (
			$(currentDiv)
				.find('h2')
				.eq(1).length > 0 &&
			$(currentDiv)
				.find('h2:last')
				.text() != 'Založit novou šablonu'
		) {
			var newFooterText = $(currentDiv)
				.find('h2:last')
				.text()
			var newFooterTextColor = $(currentDiv)
				.find('h2:last')
				.css('color')
		} else {
			var newFooterText = ''
			var newFooterTextColor = ''
		}
		var bgcolor = $(currentDiv).css('background-color')

		var svg = $(graphDiv)
			.highcharts()
			.getSVG({
				chart: {
					backgroundColor: bgcolor,
					width: newWidth,
					height: newHeight,
					borderWidth: borderWidth / 2,
					borderColor: 'white',
					marginTop: 50,
					events: {
						load: function() {
							var series = this.series
							series.forEach(function(item) {
								item.update({
									shadow: false,
									dataLabels: {
										y: 15,
									},
								})
								var itemType = item.type
								if (itemType === 'solidgauge') {
									item.update({
										dataLabels: {
											format: '<div style="font-size: 33px">{y}%</div>',
										},
									})
								}
							})
						},
					},
				},
				title: {
					text: newHeaderText,
					floating: true,
					align: 'center',
					y: 20,
					style: {
						color: newHeaderTextColor,
						fontSize: h2size,
					},
				},
				credits: {
					text: newFooterText,
					position: {
						align: 'center',
						y: -15,
					},
					style: {
						color: newFooterTextColor,
						fontSize: h2size,
					},
					enabled: true,
				},
				legend: { y: -20 },
			})
		var canvas, xml
		canvas = document.createElement('canvas')
		canvas.className = 'screenShotTempCanvas'
		xml = svg

		// Removing the name space as IE throws an error
		xml = xml.replace(/xmlns=\"http:\/\/www\.w3\.org\/2000\/svg\"/, '')

		//draw the SVG onto a zcanvas
		canvg(canvas, xml)
		$(canvas).insertAfter(svg)
		//hide the SVG element
		$(svg).attr('class', 'tempHide')
		$(svg).hide()

		var imgData = canvas.toDataURL('image/png')
		exportForm.append('<input type="hidden" name="img[]" value="' + imgData + '">')
		//$('body').append('<img src="' + imgData + '">');
	} else {
		html2canvas(divlist[i], {
			letterRendering: true,
			onrendered: function(canvas) {
				imgData = canvas.toDataURL('image/png')
				exportForm.append('<input type="hidden" name="img[]" value="' + imgData + '">')
				//$('body').append('<img src="' + imgData + '">');
			},
		})
	}

	i++
	setTimeout(function() {
		nextStep(i, divcount, divlist, exportForm)
	}, 100)
}

document.addEventListener('DOMContentLoaded', () => {
	$.nette.ext({
		success: function(payload) {
			if (payload.type !== undefined && payload.type === 'pptx-export') {
				if (payload.link) {
					$('aside').hide()
					setTimeout(function() {
						var link = payload.link
						location.href = link
					}, 10)
					setTimeout(function() {
						window.location.reload()
					}, 4000)
				}
			}
		},
	})
})

export function exportPdf(e) {
	const html = document.documentElement.innerHTML
	const exportLink = $(e).data('link-pdf-export')
	$('#loader').addClass('progress')

	axios({
		method: 'post',
		responseType: 'blob',
		url: exportLink,
		data: {
			html: `${html}`,
			format: 'full',
		},
	})
		.then(response => {
			const fileName = 'export'
			const blob = new Blob([response.data], { type: 'application/pdf' })

			saveAs(URL.createObjectURL(blob), `${fileName}.pdf`)
		})
		.finally(() => {
			$('#loader').removeClass('progress')
		})
}
